import React, { useEffect, useState } from 'react'

import { Box, Button, Grid, Zoom } from '@mui/material'
import { DeckCanvas } from 'components/mobileDeckCanvas/DeckCanvas'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Legend from './components/Legend'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import BigNumber from 'bignumber.js'
import {
  addMaintenanceAction,
  addWearAction,
  addWearAppAction,
  setHistoryWithForm
} from 'pages/common/maintenance/logic'
import { DeckMode } from 'common/deckMode'
import MaintenanceSummaryForm from '../components/SummaryForm'
import { useInView } from 'react-intersection-observer'
import { useNotification, FormDialog } from 'components'
import { DeckRevisionHistory } from 'models'
import { useTheme } from '@mui/material/styles'
import ConfirmForm from 'pages/deck/common/ConfirmForm'
import { useCurrentUser } from 'hooks'
import { createDeckRevisionHistory, updateDeckRevisionHistory } from 'pages/deck/common/historyData'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import { ProgressIndicator } from 'components/maintenanceSelector/wearapp/components/ProgressIndicator'

const defaultModalState = { isOpen: false, title: '', data: null, buttonText: '' }
const defaultMaintenanceData = {
  deckRevisionHistory: new DeckRevisionHistory({}),
  photos: []
}

export const MobileDeckCanvasAndMaintenanceForm = ({ onSwitchView }) => {
  const [mouseCanvasPosition, setMouseCanvasPosition] = useState({ x: 0, y: 0 })
  const [awaitingActionId, setAwaitingActionId] = useState(-1)
  const [materialSelected, setMaterialSelected] = useState('')
  const { ref: scrollingRef, inView, entry } = useInView({ delay: 100, trackVisibility: true, threshold: 0.1 })
  const [completeModalState, setCompleteModalState] = useState(defaultModalState)
  const theme = useTheme()
  const { getUserName, getTransactionMetaData } = useCurrentUser()
  const { state } = useLocation()
  const [isLoading, setIsLoading] = useState(false)

  const {
    deck,
    maintenanceData,
    inMaintenanceMode,
    setInMaintenanceMode,
    setMaintenanceData,
    selectedPanel,
    setSelectedPanel,
    memoizedDeckRevision: deckRevision,
    getAction,
    actions,
    wearAnalysisResults,
    database,
    history,
    maintenanceFrequency
  } = useCurrentDeckContext()

  useEffect(() => {
    localStorage.setItem('mouseCanvasPosition', JSON.stringify(mouseCanvasPosition))
  }, [mouseCanvasPosition])

  const { notify } = useNotification()

  async function onComplete(data) {
    console.log('data', { data })
    try {
      setIsLoading(true)
      if (!data?.isSave) {
        return
      }
      let clone = structuredClone(maintenanceData)
      // get all 'unsaved' wearapp results
      const wearAppResults = clone.deckRevisionHistory.Details.filter(
        ({ HistoryAction }) => HistoryAction === actions.ViewWearAppResult.id
      )

      for (const result of wearAppResults) {
        const wearAppResult = wearAnalysisResults[result.extraData.uuid]
        clone = addWearAppAction(
          wearAppResult,
          clone,
          result.Panel,
          wearAppResult.replacePanel?.replacePanel === 'replace' ? actions.Replace.id : actions.NoChange.id,
          deckRevision
        )
      }
      clone.deckRevisionHistory.RevisionId = deckRevision.id
      clone.deckRevisionHistory.RevisionNumber = 1
      clone.deckRevisionHistory.HistoryType = 'Maintenance'

      clone.deckRevisionHistory.CustomerId = deck.CustomerId
      clone.deckRevisionHistory.SiteId = deck.SiteId

      let photoKeys = []

      for (let i = 0; i < clone.photos.length; i++) {
        const photo = clone.photos[i]

        //if the photo already has a key, it was uploaded beforehand. No need to upload again
        if (photo.key) {
          photoKeys.push({ Key: photo.key, Caption: photo.caption, Height: photo.height, Width: photo.width })
        } else {
          const fileName = `${deckRevision.id}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}-${i}.png`

          try {
            const result = await Storage.put(fileName, photo.file, {
              metadata: { original: 'true' }
            })
            photoKeys.push({ Key: result.key, Caption: photo.caption, Height: photo.height, Width: photo.width })
          } catch (err) {
            console.error(err)
            notify('Could not upload photos!')
            return
          }
        }
      }
      clone.deckRevisionHistory.Details = clone.deckRevisionHistory.Details.map((data) => {
        if (data.extraData) {
          delete data.extraData
        }
        return data
      })
      clone.deckRevisionHistory.Photos = photoKeys

      if (state?.isEditingOld) {
        if (!clone.deckRevisionHistory.Updates) {
          clone.deckRevisionHistory.Updates = []
        }
        clone.deckRevisionHistory.Updates.push({
          User: getUserName(),
          Date: dayjs().toISOString(),
          Description: data.data.Description
        })
        const existing = database.deckRevisionHistories.find((d) => d.id === clone.deckRevisionHistory.id)

        if (existing) {
          updateDeckRevisionHistory(existing, clone.deckRevisionHistory, getTransactionMetaData())
          notify('Successfully updated Maintenance.')
        } else {
          notify('Maintenance not found!')
        }
      } else {
        clone.deckRevisionHistory.CreatedBy = getUserName()
        clone.deckRevisionHistory.ShortDescription = data.data.Description
        createDeckRevisionHistory(clone.deckRevisionHistory, getTransactionMetaData())
        notify('Successfully created Maintenance.')
      }
      setInMaintenanceMode(false)

      setMaintenanceData(defaultMaintenanceData)
      setCompleteModalState(defaultModalState)
      history.clearHistory()
    } finally {
      setIsLoading(false)
    }
  }

  // Action on clicking panels
  function onPanelClick(data, e) {
    if (data) {
      if (new BigNumber(awaitingActionId).isGreaterThanOrEqualTo(0)) {
        const result = addMaintenanceAction(
          getAction(awaitingActionId),
          awaitingActionId,
          [data],
          maintenanceData,
          actions
        )

        setMaintenanceData(result.clone)
        setAwaitingActionId(result.actionId)
        setSelectedPanel([])
      } else {
        setSelectedPanel([data])
        if (onSwitchView) {
          const position = { x: e.clientX, y: e.clientY }
          setMouseCanvasPosition(position)
        } else {
          setMouseCanvasPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 })
        }
      }
    } else {
      setSelectedPanel([])
    }

    setMaterialSelected('')
  }

  function onMaintenanceTypeClick(action, extraData) {
    const result = addMaintenanceAction(action, awaitingActionId, selectedPanel, maintenanceData, actions, extraData)

    setMaintenanceData(result.clone)
    setAwaitingActionId(result.actionId)
    setSelectedPanel([])
  }

  function onWearSubmit(form) {
    const clone = addWearAction(form, maintenanceData, selectedPanel, actions.NoChange.id)
    setMaintenanceData(clone)
    setSelectedPanel([])
  }

  // Cancelling deck maintennace wihtout submitting
  function cancelAwaitingAction() {
    if (new BigNumber(awaitingActionId).isGreaterThanOrEqualTo(0)) {
      const clone = structuredClone(maintenanceData)
      clone.deckRevisionHistory.Details.pop()
      setMaintenanceData(clone)
      setAwaitingActionId(-1)
    }
  }

  // Action onclicking B.O.M. table
  function onBomClick(kit) {
    setSelectedPanel([])
    setMaterialSelected(kit.MaterialNumber)
  }

  function onFormChange(form) {
    const clone = setHistoryWithForm(form, maintenanceData)
    setMaintenanceData(clone)
  }

  function onCancel() {
    setInMaintenanceMode(false)
    notify('Cancelled Maintenance.')
    setMaintenanceData(defaultMaintenanceData)
  }

  return (
    <>
      <Grid item xs={12} sx={{ marginBottom: '2em' }}>
        <Grid item mb="1em">
          <DeckCanvas
            data={deckRevision}
            maintenance={maintenanceData.deckRevisionHistory}
            mouseCanvasPosition={mouseCanvasPosition}
            onPanelClick={onPanelClick}
            onMaintenanceTypeClick={onMaintenanceTypeClick}
            selected={selectedPanel}
            materialSelected={materialSelected}
            onWearSubmit={onWearSubmit}
            awaitingActionId={awaitingActionId}
            cancelAwaitingAction={cancelAwaitingAction}
            mode={inMaintenanceMode ? DeckMode.maintenance : DeckMode.default}
            onSwitchView={onSwitchView}
          />
        </Grid>
        {inMaintenanceMode && !onSwitchView ? (
          <Grid item xs={12}>
            <Legend />
          </Grid>
        ) : null}
      </Grid>

      {!onSwitchView &&
        (typeof maintenanceFrequency === 'undefined' ? (
          <Grid container alignItems="center" justifyContent="center">
            <ProgressIndicator />
          </Grid>
        ) : (
          <Grid ref={scrollingRef} item xs={12}>
            <MaintenanceSummaryForm
              deckRevision={deckRevision}
              selected={selectedPanel}
              materialSelected={materialSelected}
              onBomClick={onBomClick}
              onChange={onFormChange}
              isMobile
              inMaintenanceMode={inMaintenanceMode}
              data={maintenanceData}
            />
          </Grid>
        ))}

      {inMaintenanceMode && !onSwitchView ? (
        <Grid container direction="row" mt={1} spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: '100%' }}
              onClick={() => {
                setCompleteModalState({
                  isOpen: true,
                  title: 'Confirm Maintenance',
                  buttonText: 'Create',
                  data: null
                })
              }}>
              Publish
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onCancel} sx={{ width: '100%' }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {!onSwitchView && (
        <>
          <Zoom in={!inView}>
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                position: 'fixed',
                bottom: '54px',
                marginLeft: '-15px'
              }}>
              <Button
                sx={{
                  height: '20px',
                  backgroundColor: `${theme.palette.primary.dark}`
                }}
                onClick={() => entry.target?.scrollIntoView({ behavior: 'smooth' })}>
                <KeyboardArrowDownIcon fontSize="large" color="secondary" />
              </Button>
            </Box>
          </Zoom>
          {completeModalState.isOpen && (
            <FormDialog isLoading={isLoading} modalState={completeModalState} onOpenChange={onComplete} isMobile>
              <ConfirmForm name="Maintenance" />
            </FormDialog>
          )}
        </>
      )}
    </>
  )
}
