/**
 *
 * "Heatmap page with decklayout colored as wear amount"
 *
 *
 * @file   HeatMapPage.js
 * @author Lateral
 * @since  2023
 */
import { DeckCanvas } from 'components'
import { Box, Grid, MenuItem, OutlinedInput, Paper, Select, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import BomTable from 'components/tables/bom/BomTable'
import React, { useContext, useEffect, useState } from 'react'
import { useMaintenance } from 'hooks'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { DeckMode } from 'common/deckMode'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
import BigNumber from 'bignumber.js'
import { pickHeatColour } from 'common/heatColours'
import { dateFormat } from 'common/dates'
import { DeckCanvas as MobileDeckCanvas } from 'components/mobileDeckCanvas/DeckCanvas'
import { DeckLayoutContainer } from '../common/DeckLayoutContainer'

function HeatMapPage() {
  /**
   * Generates Heat Map page deck laouyt, forms and buttons
   *
   * @function
   *
   * @returns {object} - Heat Map page
   */
  const [selected, setSelected] = useState([])
  const [materialSelected, setMaterialSelected] = useState('')
  const [lowWearLife, setLowWearLife] = useState(0)
  const [highWearLife, setHighWearLife] = useState(0)
  const { deckId, screenId, database } = useContext(CurrentDeckContext)
  const theme = useTheme()
  const { actions } = useMaintenance()

  const [viewType, setViewType] = useState('3D')

  const switchViewType = () => {
    setViewType((currentViewType) => (currentViewType === '3D' ? '2D' : '3D'))
  }

  const screen = database.screens.find((s) => s.id === screenId)
  const deck = database.decks.find((d) => d.id === deckId)
  const deckRevision = getDeckRevision()
  const maxRows = deckRevision?.Size.Rows
  const historyList = getHistoryList()

  const [startHistory, setStartHistory] = useState(historyList[historyList.length - 1])
  const [endHistory, setEndHistory] = useState(historyList[0])

  const heatMapData = getHeatMapData()
  const averageWearLives = getAverageWearLives()

  //set the limits between the low-mid-high of Wear life.
  useEffect(() => {
    if (deckRevision) {
      if (lowWearLife === 0) {
        setLowWearLife(Number(new BigNumber(deckRevision.Size.Rows).dividedBy(3).toFixed(0)))
      }
      if (highWearLife === 0) {
        setHighWearLife(Number(new BigNumber(deckRevision.Size.Rows).dividedBy(3).times(2).toFixed(0)))
      }
    }
  }, [])

  // Get leatest revison
  function getDeckRevision() {
    const revisions = database.deckRevisions
      .filter((d) => d.DeckId == deckId && d.IsPublished)
      .sort((a, b) => b.RevisionNumber - a.RevisionNumber)
    if (revisions.length) {
      const revision = structuredClone(revisions[0])
      return revision
    }
  }

  // Get all revisons
  function getDeckRevisions() {
    return database.deckRevisions
      .filter((d) => d.DeckId == deckId && d.IsPublished)
      .sort((a, b) => b.RevisionNumber - a.RevisionNumber)
  }

  //Get all maintenance histories
  function getHistoryList(filter = false) {
    let data = getDeckRevisions().map((r) => {
      const histories = database.deckRevisionHistories.filter(
        (h) => h.RevisionId === r.id && h.HistoryType === 'Maintenance'
      )
      console.log('histories', { histories })
      if (histories.length) {
        return histories.map((h, i) => {
          const date = dayjs(h.DatePerformed ?? h.createdAt)
          return {
            id: h.id,
            revisionNumber: r.RevisionNumber,
            maintenanceNumber: i,
            name: `${date.format(dateFormat)} - Revision ${r.RevisionNumber} - Maintenance ${i} ${
              h.ShortDescription?.length ? ` - ${h.ShortDescription}` : ''
            }`,
            date: date
          }
        })
      }
    })

    data = data.flat().filter((d) => d)

    if (filter) {
      data = data.filter((d) => d.date.isBetween(startHistory?.date, endHistory?.date, 'day', '[]'))
    }

    return data.sort((a, b) => {
      if (a.date.isBefore(b.date)) {
        return 1
      } else if (a.date.isAfter(b.date)) {
        return -1
      } else {
        return 0
      }
    })
  }

  function setHistory(id, setter) {
    const history = database.deckRevisionHistories.find((d) => d.id === id)
    setter({
      id: history.id,
      date: dayjs(history.DatePerformed ?? history.createdAt)
    })
  }

  function getHeatMapData() {
    const historyRefs = getHistoryList()
    const histories = database.deckRevisionHistories.filter((d) => historyRefs.map((h) => h.id).includes(d.id))
    const total = new BigNumber(histories.length)

    let timePeriod = new BigNumber(endHistory?.date.diff(startHistory?.date, 'week'))
    if (timePeriod.isEqualTo(0) || timePeriod.isNaN()) {
      timePeriod = new BigNumber(1)
    }

    let model = { Total: total, Panels: [], SideLiners: [] }

    //Heatmap data is based on the total maintenances done during a specific time period.
    deckRevision?.Panels.forEach((panel) => {
      const count = histories.filter((h) =>
        h.Details.find(
          (d) =>
            d.Panel?.Position.Column === panel.Position.Column &&
            d.Panel?.Position.Row === panel.Position.Row &&
            d.HistoryAction === actions.Replace.id
        )
      ).length

      const averageCalculation = count > 0 ? total.plus(count).dividedBy(count).toNumber() : 1

      model.Panels.push({
        Column: panel.Position.Column,
        Row: panel.Position.Row,
        MaterialNumber: panel.MaterialNumber,
        WearCount: count,
        WearCalculation: timePeriod.dividedBy(averageCalculation)
      })
    })

    deckRevision?.SideLiners.forEach((sideLiner) => {
      const count = histories.filter((h) =>
        h.Details.find(
          (d) =>
            d.SideLiner?.StartPosition === sideLiner.StartPosition &&
            d.SideLiner?.Side === sideLiner.Side &&
            d.HistoryAction === actions.Replace.id
        )
      ).length

      const averageCalculation = count > 0 ? total.plus(count).dividedBy(count).toNumber() : 1

      model.SideLiners.push({
        StartPosition: sideLiner.StartPosition,
        Side: sideLiner.Side,
        MaterialNumber: sideLiner.MaterialNumber,
        WearCount: count,
        WearCalculation: timePeriod.dividedBy(averageCalculation)
      })
    })

    return model
  }

  //Calculate average wear lives. The outcome devided into three categories
  //1. Low (Feed)
  //2. Mid (Intermediate)
  //3. High (Discharge)
  function getAverageWearLives() {
    let timePeriod = new BigNumber(endHistory?.date.diff(startHistory?.date, 'week'))
    const one = new BigNumber(1)

    if (timePeriod.isEqualTo(0) || timePeriod.isNaN()) {
      timePeriod = one
    }

    const feedPanels = heatMapData.Panels.filter((p) => new BigNumber(p.Row).isLessThanOrEqualTo(lowWearLife))
    const feedWearCount = new BigNumber(feedPanels.reduce((total, f) => total + f.WearCount, 0))
    const feedCalculation =
      feedPanels.length > 0 ? feedWearCount.plus(feedPanels.length).dividedBy(feedPanels.length) : one
    const feedResult = new BigNumber(timePeriod.dividedBy(feedCalculation).toFixed(2))

    const intermediatePanels = heatMapData.Panels.filter(
      (p) => new BigNumber(p.Row).isGreaterThan(lowWearLife) && new BigNumber(p.Row).isLessThanOrEqualTo(highWearLife)
    )
    const intermediateWearCount = new BigNumber(intermediatePanels.reduce((total, m) => total + m.WearCount, 0))
    const intermediateCalculation =
      intermediatePanels.length > 0
        ? intermediateWearCount.plus(intermediatePanels.length).dividedBy(intermediatePanels.length)
        : one
    const intermediateResult = new BigNumber(timePeriod.dividedBy(intermediateCalculation).toFixed(2))

    const dischargePanels = heatMapData.Panels.filter((p) => new BigNumber(p.Row).isGreaterThan(highWearLife))
    const dischargeWearCount = new BigNumber(dischargePanels.reduce((total, d) => total + d.WearCount, 0))
    const dischargeCalculation =
      dischargePanels.length > 0
        ? dischargeWearCount.plus(dischargePanels.length).dividedBy(dischargePanels.length)
        : one
    const dischargeResult = new BigNumber(timePeriod.dividedBy(dischargeCalculation).toFixed(2))

    return {
      Feed: feedResult.toNumber(),
      FeedColour: pickHeatColour(feedResult.dividedBy(timePeriod).times(100).toNumber(), theme),
      Intermediate: intermediateResult.toNumber(),
      IntermediateColour: pickHeatColour(intermediateResult.dividedBy(timePeriod).times(100), theme),
      Discharge: dischargeResult.toNumber(),
      DischargeColour: pickHeatColour(dischargeResult.dividedBy(timePeriod).times(100), theme)
    }
  }

  function onPanelClick(selected) {
    if (selected) {
      setSelected([selected])
    } else {
      setSelected([])
    }
    setMaterialSelected('')
  }

  //Action on clicking B.O.M. table
  function onBomClick(kit) {
    setSelected([])
    setMaterialSelected(kit.MaterialNumber)
  }

  //Action on changing panle selection
  function onSelectChange(data) {
    setSelected(data)
    setMaterialSelected('')
  }

  if (!screen || !deck || !deckRevision) {
    return null
  }

  if (Object.entries(screen).length <= 0 || Object.entries(deck).length <= 0) {
    return <Typography>No Deck Revision found.</Typography>
  }

  return (
    <DeckLayoutContainer
      headerTitle="Heat Map"
      Deck={
        <>
          {viewType === '3D' ? (
            <DeckCanvas
              data={deckRevision}
              heatMapData={heatMapData}
              selected={selected}
              materialSelected={materialSelected}
              onSelectChange={onSelectChange}
              onSwitchView={switchViewType}
              mode={DeckMode.heatMap}
            />
          ) : (
            <MobileDeckCanvas
              onSwitchView={switchViewType}
              data={deckRevision}
              heatMapData={heatMapData}
              selected={selected}
              materialSelected={materialSelected}
              onSelectChange={onSelectChange}
              mode={DeckMode.heatMap}
              onPanelClick={onPanelClick}
            />
          )}
        </>
      }
      topRightElement={
        <Box
          minHeight="0"
          minWidth="0"
          overflow="scroll"
          sx={{ display: 'flex', flexDirection: 'column', minHeight: '0', gap: '20px' }}>
          <Box sx={{ marginBottom: '2em' }}>
            <Typography className="deck-title" variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
              Heat Map Details
            </Typography>
            <Paper elevation={8} sx={{ margin: '0.5em 0', paddingBottom: '0.5em', backgroundImage: 'none' }}>
              <Typography
                variant="h5"
                component="h5"
                sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '0.5em 1em' }}>
                Select Time Frame to Compare
              </Typography>
              <Stack direction="column" spacing={2} sx={{ margin: '1em' }}>
                {historyList.length ? (
                  <>
                    <Select
                      onChange={(e) => setHistory(e.target.value, setStartHistory)}
                      value={startHistory?.id}
                      sx={{ width: '100%' }}>
                      {historyList.map((s) => {
                        return (
                          <MenuItem key={s.id} value={s.id}>
                            {s.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <Select
                      onChange={(e) => setHistory(e.target.value, setEndHistory)}
                      value={endHistory?.id}
                      sx={{ width: '100%' }}>
                      {historyList.map((s) => {
                        return (
                          <MenuItem key={s.id} value={s.id}>
                            {s.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </>
                ) : (
                  <Typography>No Maintenance done on this Deck.</Typography>
                )}
              </Stack>
              <Typography
                variant="h5"
                component="h5"
                sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '0.5em 1em' }}>
                Average Wear Life (Weeks)
              </Typography>
              <Grid container sx={{ padding: '1em 1.3em' }}>
                <Grid item xs={4}>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h5" component="h5" sx={{ color: `${theme.palette.supporting.pale}` }}>
                        Feed End:
                      </Typography>
                      <Typography>Row 1 to </Typography>
                      <OutlinedInput
                        defaultValue={lowWearLife}
                        onChange={(e) => setLowWearLife(Number(e.currentTarget.value))}
                        sx={{ height: '2em', width: '4em' }}
                        type="number"
                        inputProps={{ min: 1, max: `${highWearLife - 1}`, step: 1 }}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h5" component="h5" sx={{ color: `${theme.palette.supporting.pale}` }}>
                        Wear Life:
                      </Typography>
                      <Typography
                        sx={{ color: `${averageWearLives.FeedColour}` }}>{`${averageWearLives.Feed} wks`}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h5" component="h5" sx={{ color: `${theme.palette.supporting.pale}` }}>
                        Intermediate:
                      </Typography>
                      <Typography>{`Row ${lowWearLife + 1} to `}</Typography>
                      <OutlinedInput
                        defaultValue={highWearLife}
                        onChange={(e) => setHighWearLife(Number(e.currentTarget.value))}
                        sx={{ height: '2em', width: '4em' }}
                        type="number"
                        inputProps={{ min: `${lowWearLife + 1}`, max: `${maxRows}`, step: 1 }}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h5" component="h5" sx={{ color: `${theme.palette.supporting.pale}` }}>
                        Wear Life:
                      </Typography>
                      <Typography sx={{ color: `${averageWearLives.IntermediateColour}` }}>
                        {`${averageWearLives.Intermediate} wks`}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ padding: '0.25em 0' }}>
                      <Typography variant="h5" component="h5" sx={{ color: `${theme.palette.supporting.pale}` }}>
                        Discharge End:
                      </Typography>
                      <Typography>{`Row ${highWearLife + 1} to ${maxRows}`}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h5" component="h5" sx={{ color: `${theme.palette.supporting.pale}` }}>
                        Wear Life:
                      </Typography>
                      <Typography sx={{ color: `${averageWearLives.DischargeColour}` }}>
                        {`${averageWearLives.Discharge} wks`}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Box>
      }
      bottomRightElement={
        <BomTable
          data={deckRevision}
          selected={selected}
          materialSelected={materialSelected}
          onClick={onBomClick}
          deckMode={DeckMode.heatMap}
          heatMapData={heatMapData}
        />
      }
    />
  )
}

export default HeatMapPage
