import React, { createContext, useCallback, useContext, useEffect, useState, useRef, useMemo } from 'react'
import { ErrorDialog } from './components/ErrorDialog'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import dayjs from 'dayjs'
import { Storage } from 'aws-amplify'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { MaintenanceDialog } from '../MaintenanceDialog'
import { Button, Grid } from '@mui/material'
import { ReplacePanelProcessEfficiency } from './components/ReplacePanelProcessEfficiency'
import { detectCorners } from 'service/panelCornerDetection'
import { useWearAppAnalysisContext } from './WearAppAnalysisContext'
import { removeWearAppResults } from 'pages/common/maintenance/logic'
import { LoadingIndicator } from './components/LoadingIndicator'

const WearAppContext = createContext({
  // eslint-disable-next-line no-unused-vars
  startWearAnalysis: () => {},
  error: undefined,
  selectedPanel: undefined,
  polygonPoints: undefined,
  image: undefined,
  isLoading: undefined,
  loadingMessage: undefined,
  onSelectImageClick: () => {},
  onWearAppSubmit: () => {},
  setImage: () => {},
  isFile: undefined,
  activeTab: undefined,
  onTabchange: () => {},
  openReplacePanel: undefined,
  setOpenEfficiencyReplacePanel: () => {},
  photoKeyRef: undefined,
  onReplacePanelEfficencyActionButtonPressed: () => {}
})

export const useWearAppContext = () => useContext(WearAppContext)

export const WearAppContextProvider = () => {
  const {
    selectedPanel,
    onCompleteWearAppPress,
    maintenanceData,
    setMaintenanceData,
    isDesktop,
    deckRevision,
    panelInstallDate,
    setSelectedPanel,
    memoizedDeckRevision,
    siteId,
    customerId,
    deckId
  } = useCurrentDeckContext()
  const selectedPanelRef = useRef(selectedPanel)

  const {
    addNotification,
    startWearAnalysis: startWearAnalysis_,
    currentAnalysisResultsRef,
    selectedPanelRefs
  } = useWearAppAnalysisContext()
  const { pathname } = useLocation()

  const [error, setError] = useState(undefined)
  const [image, setImage] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(undefined)
  const [isFile, setIsFile] = useState(undefined)
  const [activeTab, setActiveTab] = useState(0)
  const polygonPoints = useRef([
    [281, 133],
    [881, 133],
    [881, 733],
    [281, 733]
  ])
  const photoKeyRef = useRef()
  const requestFolderRef = useRef()
  const currentStatus = useRef({
    isUploading: false,
    hasUploadError: false,
    isFetchingCornerPoints: false,
    hasConfirmedReplacePanel: false
  })
  const replacePanelRef = useRef()
  const efficiencyRef = useRef()
  const [openEfficiencyReplacePanel, setOpenEfficiencyReplacePanel] = useState(false)
  const [initialLoading, setIntialLoading] = useState(true)
  const navigate = useNavigate()

  const onReplacePanelEfficencyActionButtonPressed = useCallback(({ replacePanel, efficiency, reason }) => {
    if (reason === 'cancel') {
      setOpenEfficiencyReplacePanel(false)
      return
    }
    replacePanelRef.current = replacePanel
    efficiencyRef.current = efficiency
    setOpenEfficiencyReplacePanel(false)
    if (currentStatus.current.isUploading || currentStatus.current.isFetchingCornerPoints) {
      setLoadingMessage(
        currentStatus.current.isUploading ? 'Uploading image for analysis ...' : 'Detecting corners ...'
      )
      setIsLoading(true)
    }
  }, [])

  useEffect(() => {
    if (
      (selectedPanelRef.current?.length > 0 && Object.keys(selectedPanelRef.current[0] ?? {}).length > 0) ||
      pathname === '/equipment/maintenance/wearapp/results'
    ) {
      setIntialLoading(false)
    } else {
      navigate(isDesktop ? '/equipment/maintenance' : '/mobile/maintenance')
    }
  }, [])

  useEffect(() => {
    const fetchPoints = async () => {
      addNotification({
        children: 'The image is being processed in the background for corner points',
        type: 'info',
        autoHideDuration: 3000,
        key: 'corner_points_info'
      })
      if (!photoKeyRef.current || !requestFolderRef.current) {
        requestFolderRef.current = `public/${customerId}/${deckId}/${dayjs().format('YYYY-MM-DD-HH-mm-ss')}`
        currentStatus.current.isUploading = true
        const fileExtension = isFile ? image.name.split('.').pop() : 'txt'
        photoKeyRef.current = `${requestFolderRef.current}/wear_analysis_request_image.${fileExtension}`
        try {
          await Storage.put(photoKeyRef.current.replace('public/', ''), image)
        } catch (e) {
          currentStatus.current.hasUploadError = true
          setOpenEfficiencyReplacePanel(false)
          setError('An error occurred while uploading image. Please try again')
        } finally {
          currentStatus.current.isUploading = false
        }
      }
      if (currentStatus.current.hasUploadError) {
        return
      }
      try {
        currentStatus.current.isFetchingCornerPoints = true
        const { isSuccess, result } = await detectCorners(photoKeyRef.current, siteId)

        if (isSuccess && result.length === 4) {
          polygonPoints.current = result
          addNotification({
            children: 'Corner points were successfully detected',
            type: 'success',
            autoHideDuration: 3000,
            key: 'corner_points_success'
          })
        } else {
          addNotification({
            children: 'An error occurred while detecting corner points',
            type: 'warning',
            autoHideDuration: 3000,
            key: 'corner_points_warning'
          })
          polygonPoints.current = [
            [281, 133],
            [881, 133],
            [881, 733],
            [281, 733]
          ]
        }
      } finally {
        currentStatus.current.isFetchingCornerPoints = false
        setIsLoading(false)
        navigate('canvas')
      }
    }
    if (image) {
      fetchPoints()
      setOpenEfficiencyReplacePanel(true)
    }
  }, [image])

  const startWearAnalysis = useCallback(async (shouldNavigate = true, selectedPanel) => {
    startWearAnalysis_({
      photoKey: photoKeyRef.current,
      efficiencyProps: efficiencyRef.current,
      replaceProps: replacePanelRef.current,
      requestUUID: Date.now(),
      templateCoords: polygonPoints.current,
      selectedPanel: selectedPanel ?? selectedPanelRef.current[0],
      requestFolder: requestFolderRef.current
    })
    addNotification({
      children: "We are background analysing the image, we will notify you when it's ready",
      type: 'info',
      autoHideDuration: 4000,
      key: 'analysis_info'
    })
    if (shouldNavigate) {
      navigate(isDesktop ? '/equipment/maintenance' : '/mobile/maintenance')
    }
  }, [])

  const onSelectImageClick = useCallback((e) => {
    const file = e.target.files[0]
    if (file) {
      photoKeyRef.current = undefined
      setImage(file)
      setIsFile(true)
      e.target.value = ''
    }
  }, [])

  const cancel = useCallback(() => {
    if (currentAnalysisResultsRef.current) {
      const clone = structuredClone(maintenanceData)
      removeWearAppResults(clone.deckRevisionHistory, selectedPanelRef.current[0])
      setMaintenanceData(clone)
    }

    navigate(isDesktop ? '/equipment/maintenance' : '/mobile/maintenance')
  }, [maintenanceData])

  const selectedPanelFailureWorkingDepth = useMemo(() => {
    if (selectedPanel.length === 0) {
      return
    }
    const kit = deckRevision.Kits.find(({ MaterialNumber }) => MaterialNumber === selectedPanel[0].MaterialNumber)
    return kit?.FailureWorkingDepth || '0'
  }, [selectedPanel, deckRevision])

  const onNavigateButtonPressed = useCallback(
    (direction) => {
      const selectedPanel = selectedPanelRef.current[0]
      switch (direction) {
        case 'up':
          selectedPanelRef.current = memoizedDeckRevision.Panels.filter(
            ({ Position }) =>
              Position?.Column === selectedPanel.Position.Column && Position?.Row === selectedPanel.Position.Row - 1
          )
          break
        case 'down':
          selectedPanelRef.current = memoizedDeckRevision.Panels.filter(
            ({ Position }) =>
              Position.Column === selectedPanel.Position.Column && Position.Row === selectedPanel.Position.Row + 1
          )

          break
        case 'left':
          selectedPanelRef.current = memoizedDeckRevision.Panels.filter(
            ({ Position }) =>
              Position.Column === selectedPanel.Position.Column - 1 && Position.Row === selectedPanel.Position.Row
          )

          break
        case 'right':
          selectedPanelRef.current = memoizedDeckRevision.Panels.filter(
            ({ Position }) =>
              Position.Column === selectedPanel.Position.Column + 1 && Position.Row === selectedPanel.Position.Row
          )

          break
      }
      setImage(undefined)
      startWearAnalysis(false, selectedPanel)
      setSelectedPanel(selectedPanelRef.current)
      navigate(-1)
    },
    [memoizedDeckRevision]
  )

  const value = useMemo(() => {
    return {
      startWearAnalysis,
      error,
      selectedPanel: selectedPanelRef.current[0],
      polygonPoints,
      onSelectImageClick,
      image,
      isLoading,
      loadingMessage,
      setImage,
      isFile,
      activeTab,
      onTabchange: setActiveTab,
      setOpenEfficiencyReplacePanel,
      photoKeyRef,
      setIsFile,
      onReplacePanelEfficencyActionButtonPressed,
      onNavigateButtonPressed
    }
  }, [
    startWearAnalysis,
    error,
    onSelectImageClick,
    image,
    isLoading,
    loadingMessage,
    setImage,
    isFile,
    activeTab,
    setActiveTab,
    setOpenEfficiencyReplacePanel,
    setIsFile,
    onReplacePanelEfficencyActionButtonPressed,
    onNavigateButtonPressed
  ])

  return (
    <WearAppContext.Provider value={value}>
      {!initialLoading && (
        <>
          <Grid container style={{ width: '100%', height: '100%' }}>
            <Grid container item gap={2} justifyContent="flex-end">
              <Grid item>
                <Button onClick={cancel} color="secondary" variant="outlined">
                  Cancel
                </Button>
              </Grid>
              {pathname.includes('wearapp/results') && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      onCompleteWearAppPress(
                        currentAnalysisResultsRef.current.uuid,
                        selectedPanelRefs[currentAnalysisResultsRef.current.uuid]
                      )
                    }>
                    Complete wearapp
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid
              mt={2}
              style={{
                height: '100%',
                flex: '1 1 auto',
                overflow: 'auto'
              }}
              item
              container>
              <Outlet />
            </Grid>
          </Grid>
          <ErrorDialog onOkPressed={setError} error={error} />
          <MaintenanceDialog open={isLoading}>
            <LoadingIndicator loadingMessage={loadingMessage} />
          </MaintenanceDialog>
          <MaintenanceDialog open={openEfficiencyReplacePanel}>
            <ReplacePanelProcessEfficiency
              safetyDepth={selectedPanelFailureWorkingDepth}
              nextShutDownDate={maintenanceData.deckRevisionHistory.NextShutDownDate}
              panelInstallDate={panelInstallDate}
            />
          </MaintenanceDialog>
        </>
      )}
    </WearAppContext.Provider>
  )
}
